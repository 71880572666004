.container {
  height: 100vh;
  margin: 0;
  display: flex; /* Enable flexbox */
  flex-direction: column; /* Stack children vertically */
  gap: 10px; /* Optionsal: space between each child */
  align-items: center;
  margin-top: 20vh;
}

.header {
  margin: 5px;
}

.subHeader {
  margin-top: 5px;
  margin-bottom: 25px
}


.search-div {
  display: flex;
  align-items: center; /* Vertically centers the child */
  gap: 5px;
}

.search-bar {
  width: 500px; /* Width of the search bar */
  height: 40px; /* Height of the search bar */
  border: 1px solid #dfe1e5; /* Light gray border */
  border-radius: 24px; /* Rounded corners */
  padding: 0 16px; /* Padding inside the search bar */
  font-size: 16px; /* Font size */
  outline: none; /* Remove outline on focus */
  box-shadow: none; /* Remove box shadow */
  transition: all 0.3s ease; /* Smooth transition for focus effects */
}

.search-bar:focus {
  border: 1px solid #4285f4; /* Blue border on focus */
  box-shadow: 0 1px 6px rgba(32, 33, 36, 0.28); /* Subtle shadow on focus */
}

.filter1-properties {
  margin: 10px;
  padding: 10px;
  padding-right: 30px;
  background-color: #F7F8F9;
  border-radius: 15px;
  border: none;
  appearance: none; /* Remove default arrow for custom styling */
  -webkit-appearance: none; /* Remove default arrow in WebKit browsers */
  -moz-appearance: none; /* Remove default arrow in Firefox */
}

.filter2-properties {
  margin: 10px;
  padding: 10px;
  padding-right: 30px;
  background-color: #F7F8F9;
  border-radius: 15px;
  border: none;
  appearance: none; /* Remove default arrow for custom styling */
  -webkit-appearance: none; /* Remove default arrow in WebKit browsers */
  -moz-appearance: none; /* Remove default arrow in Firefox */
}

.filter3-properties {
  margin: 10px;
  padding: 10px;
  padding-right: 30px;
  background-color: #F7F8F9;
  border-radius: 15px;
  border: none;
  appearance: none; /* Remove default arrow for custom styling */
  -webkit-appearance: none; /* Remove default arrow in WebKit browsers */
  -moz-appearance: none; /* Remove default arrow in Firefox */
}

select {
  background-image: url("/public/arrow_down.svg");
  background-size: 24px;
  background-repeat: no-repeat;
  background-position: calc(100% - 8px) center;
}
