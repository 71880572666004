.container-search {
    margin: 0;
    display: flex; /* Enable flexbox */
    flex-direction: row; /* Stack children vertically */
    gap: 10px; /* Optional: space between each child */
    align-items: left;
    margin-top: 3vh;
    margin-left: 3vh;
  }

  .row-under-search {
    align-items: left;
    margin-top: 3vh;
    margin-left: 3vh;
    width: 700px;
  }
  
  
  .search-div-search {
    display: flex;
    align-items: center; /* Vertically centers the child */
    gap: 5px;
  }
  
  .search-bar-search {
    width: 500px; /* Width of the search bar */
    height: 40px; /* Height of the search bar */
    border: 1px solid #dfe1e5; /* Light gray border */
    border-radius: 24px; /* Rounded corners */
    padding: 0 16px; /* Padding inside the search bar */
    font-size: 16px; /* Font size */
    outline: none; /* Remove outline on focus */
    box-shadow: none; /* Remove box shadow */
    transition: all 0.3s ease; /* Smooth transition for focus effects */
  }
  
  .search-bar-search:focus {
    border: 1px solid #4285f4; /* Blue border on focus */
    box-shadow: 0 1px 6px rgba(32, 33, 36, 0.28); /* Subtle shadow on focus */
  }

  .filter1-properties-search {
    margin: 10px;
    padding: 10px;
    padding-right: 30px;
    background-color: #F7F8F9;
    border-radius: 15px;
    border: none;
    appearance: none; /* Remove default arrow for custom styling */
    -webkit-appearance: none; /* Remove default arrow in WebKit browsers */
    -moz-appearance: none; /* Remove default arrow in Firefox */
  }
  
  .filter2-properties-search {
    margin: 10px;
    padding: 10px;
    padding-right: 30px;
    background-color: #F7F8F9;
    border-radius: 15px;
    border: none;
    appearance: none; /* Remove default arrow for custom styling */
    -webkit-appearance: none; /* Remove default arrow in WebKit browsers */
    -moz-appearance: none; /* Remove default arrow in Firefox */
  }
  
  .filter3-properties-search {
    margin: 10px;
    padding: 10px;
    padding-right: 30px;
    background-color: #F7F8F9;
    border-radius: 15px;
    border: none;
    appearance: none; /* Remove default arrow for custom styling */
    -webkit-appearance: none; /* Remove default arrow in WebKit browsers */
    -moz-appearance: none; /* Remove default arrow in Firefox */
  }

  .icon-container {
    margin-top: 1vh;
  }

  .splitContainer {
    display: flex;
    gap: 20px; /* Adds space between columns */
  }

  .rightSide {
    width: 900px;
  }

  .charts {
    margin-top: 10vh;
    height: 500px;
  }